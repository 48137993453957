import React from 'react'
import Left_panel from '../Left_panel'
import Sub_header from '../Sub_header'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import exportValue from '../../apiconfig'
import Moment from 'react-moment';
import ReactStarsRating from 'react-awesome-stars-rating';
import '../../components/loader.css';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import ReactLoading from 'react-loading';





const Shipment_detail = () => {
  const [value, setValue] = React.useState('1')
    const {invoice_id} = useParams();
  const [state, setState] = React.useState({ shipmentDetail: {sender:{},receiver:{},items:[] }, isLoading: true,detailFound:false,parcel_count:0  })
  const [addstate, setaddState] = React.useState({mode:"",transaction_id:""})
  const [editstate, seteditState] = React.useState({show:false,shipment_id:"",paid_to:""})
const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
const[failedstate , setfailed] = React.useState({failed:""})
const [trackingstate,setTrackingState] = React.useState({track:{},isLoading:true})
 const [stateship, setStateship] = React.useState({ shipmentDetail: {sender:{},receiver:{},items:[] }, isLoading: true,detailFound:false,parcel_count:0  })
 const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

 const toggleSidebar = () => {
     setIsSidebarOpen(!isSidebarOpen);
     const homeSection = document.querySelector('.home-section');
     if (homeSection) {
         homeSection.classList.toggle('sidebar-closed');
     }
 };
  const dimension_detail = () =>{
    let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/company_info_api`;
    let sendData = {};
  
    axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
      setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
      console.log("rnmmmmm", res);
    }).catch((e) => {
      // toast.configure()
       //toast.error("Some thing went wrong")
      console.log("----error:   ", e);
    })
  }
  React.useEffect(() => {
    dimension_detail();
    ShipmentDetail()
    status_update()
    shipment_tracking()
   
  }, [])
  const status_update = ()=>{

    let full_api = exportValue.apiUrl+'/shipment/shipment_tracking';
    let sendData= {trackingNumber:invoice_id};
       console.log("senddata",sendData)
      
    axios.post(full_api,sendData, { headers: exportValue.headers }).then(res => {        
     
       console.log("res",res)
      //  if(res.data.message=="success"){
          
      //      Swal.fire(
      //          'Success!',
      //          ' Status Updated Successfully !',
      //          'success'
      //        )
      //       //  window.location.reload(false);
      //  }

       
      //  else{
      //      Swal.fire(
      //          'Unsuccess!',
      //          'Something Went Wrong !',
      //          'success'
      //        )
      //        window.location.reload(false);
      
      //  }
   })

    }

    const ShipmentDetail = () => {

        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/shipment_detail`;
        let sendData = { shipment_id: invoice_id };
        console.log("bb",sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
          console.log("res -> ",res);
         
         
            setState({ ...state, shipmentDetail: res.data.output[0],parcel_count:res.data.output[0].items.length, isLoading:false})
          
     
        }).catch((e) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            
          })
          console.log("----error:   ", e);
        });
      };
      const shipment_failed = () => {

        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/check_failed_shipments`;
        let sendData = { shipment_id: invoice_id };
        console.log("bb",sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
          console.log("res -> ",res);
         
         
           setfailed({...failedstate , failed:res.data.output})
          
     
        }).catch((e) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            
          })
          console.log("----error:   ", e);
        });
      };
      React.useEffect(() => {
        ShipmentDetail();
        shipment_failed();
       
      }, [])

      const nameFormat = (name)=> {
        
        if(name) {
            let nameArray =  name.split(" ");
           // console.log("nameArray  ",nameArray);
            if(nameArray.length > 1) {
                let n1 = nameArray[0].substring(0,1).toUpperCase();
                let n2 = nameArray[1].substring(0,1).toUpperCase();
                name = n1+n2;
            }else {
                name = nameArray[0].substring(0,2).toUpperCase();
            }
        }else{
            name = "NO";
        }
        return name;
    }
   
   
  //  const SenderAddress = () =>{
  //   if(state.shipmentDetail.items.length > 0){
  //     console.log("==>  ",state.shipmentDetail.items);
  //     return state.shipmentDetail.items.reduce((previousValue, currentValue) =>{
  //       return previousValue.item_weight + currentValue.item_weight},{item_weight:0}) 
  //   }else {
  //     return 0
  //   }
  //  } 
  const shipment_tracking = () =>{
      
    let full_api = exportValue.apiUrl + `/shipment/shipment_tracking`;
   let sendData = {  trackingNumber:invoice_id};
   // console.log("sendData",sendData)
    
   axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
    if(res.data.status==200){
      console.log("2000 ")
       setTrackingState({...trackingstate,track:res.data.output,isLoading:false})
       // console.log("res",trackingstate.track)
       setStateship({ ...stateship, shipmentDetail: res.data.ship_detail, isLoading:false})
    }
    else{
      console.log("5000")
     setTrackingState({...trackingstate,track:res.data.output,isLoading:false})
     // console.log("res",trackingstate.track)
     setStateship({ ...stateship, shipmentDetail: res.data.ship_detail, isLoading:false})
    }
   
    
   }).catch((e) => {
     // toast.configure()
      //toast.error("Some thing went wrong")
     // console.log("----error:   ", e);
   })
 }
 
  return (
    <div>
      {/* <Sub_header/> */}
   
    {/* <div style={{marginLeft:"15px",marginRight:"15px"}}>
      
    <section class="">
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div class="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                        <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li class="breadcrumb-item">
                                <a href="#">
                                    <svg class="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                                </a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Shipment</li>
                        </ol>
                    </nav>
                    <h2 class="h4">Shipment# {invoice_id}</h2>
                {failedstate.failed!=""?   
            <div class="alert alert-danger text-center" role="alert">
              Shipment Failed Due to "{failedstate.failed}"
              </div>:""}
                    
                </div>
                <div class=" mb-2 mb-md-0">
                {state.shipmentDetail.status==0?<span class="badge rounded-pill bg-warning ">Pending</span>:state.shipmentDetail.status==10?<span class="badge rounded-pill bg-danger ">Not Picked</span>:state.shipmentDetail.status==1?<span class="badge rounded-pill bg-success ">Pick Up Awaited</span>:state.shipmentDetail.status==2?<span class="badge rounded-pill bg-warning ">Picked Up</span>:state.shipmentDetail.status==3?<span class="badge rounded-pill bg-danger ">In Transit</span>:state.shipmentDetail.status==4?<span class="badge rounded-pill bg-primary">Out For Delivery</span>:state.shipmentDetail.status==5?<span class="badge rounded-pill bg-success">Delivered</span>:state.shipmentDetail.status==6?<span class="badge rounded-pill bg-danger ">Cancelled</span>:state.shipmentDetail.status==7?<span class="badge rounded-pill bg-secondary ">Failed</span>:state.shipmentDetail.status==8?<span class="badge rounded-pill bg-warning ">NDR</span>:state.shipmentDetail.status==9?<><span class="badge rounded-pill bg-danger">Pickup Scheduled</span><br/><br/>
                            {state.shipmentDetail.pickup_date!=undefined && state.shipmentDetail.pickup_date!=""?  <small>on {state.shipmentDetail.pickup_date!=undefined?state.shipmentDetail.pickup_date:""} at {state.shipmentDetail.pickup_time!=undefined?state.shipmentDetail.pickup_time:""}</small>:""} </>:<span class="badge rounded-pill bg-danger ">Not Available</span>}
                </div>
             

            </div>
            
    </section>

    

    
    <section class="">
        <div class="row">
          <div class="col-md-8">
                
              
  
              
  
  
           
              
              <section>
                <h4>Payment Info</h4>
                  <div class="card shadow-sm mb-3">
                    <div class="card-body">
                      <div class="d-flex justify-content-between">
                          <div class="">Shipment #<a href="" class="text-info">{state.shipmentDetail.shipment_id}</a> </div>
                          <div class=""><div class="text-end"><h3 class="fs-5 "><a href="" class="text-info">Customer Invoice #{state.shipmentDetail.order_number}</a></h3></div></div>
                      </div>
                      
                      
                      <hr/>
                      <table class="table">
                        <thead>
                          <tr>
                            <th colspan="2">Particulars</th>
                            <th>Amount (₹)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Shipping Fee</td>
                            <td></td>
                            <td class=""><h6>{state.shipmentDetail.rate_price}</h6></td>
                          </tr>
                          <tr>
                            <td class="fw-700 text-end">Insurance (+)</td>
                            <td></td>
                            <td class=""><h6>{state.shipmentDetail.insurance_amount}</h6></td>
                          </tr>
                          <tr>
                            <td class="fw-700 text-end">VAT (18%)</td>
                            <td></td>
                            <td class=""><h6>{state.shipmentDetail.tax_amount}</h6></td>
                          </tr>
                          
                          
                          <tr>
                            <td class="fw-700 text-end">To be paid by customer</td>
                            <td></td>
                            <td class="text-danger"><h5>₹ {state.shipmentDetail.total_amount}</h5></td>
                          </tr>
                        </tbody>
                      </table>
                      <hr/>
                      <div class="mt-3">
                        <h5>Payment information</h5>
                        <div class="row">
                            <div class="col-12 col-md">
                              <p>Transcation ID #</p>
                              <a href="">{state.shipmentDetail.transaction_id}</a>
                            </div>
                            <div class="col-12 col-md pay_mode text-end">
                              <p>Payment Mode</p>
                              <img src="https://i.imgur.com/7gg5305.png" alt="" class="img-fluid " title="Paypal"/> 
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </section>

              
            </div>

          <div class="col-md-4 pb-3 mb-3 shipment_info_23">
            <h4>Shipment information</h4>
            <section class="bg-white p-3 mb-3">
              <p>
              {state.shipmentDetail.sender.city} <i class="fa fa-long-arrow-right" aria-hidden="true"></i> {state.shipmentDetail.receiver.city}
                <span class="text-end"><small class="text-muted"></small></span>
              </p>

              
              <p>Delivery ETA - <span class="text-muted">------</span></p>
            </section>


            

            <section class="bg-white">
                <section class="ps-4 pt-3">
                    <h3 class="fs-5 pb-3">Basic information</h3>
                    <div class="row">
                      <div class="col-md-6">
                        <small class="text-muted">Shipment Tracking #</small>
                        <h4 class="fs-5">{state.shipmentDetail.carrier_tracking_number}</h4>
                      </div>
                      <div class="col-md-6">
                        <small class="text-muted">PO/Refernce #</small>
                        <h4 class="fs-5">293848</h4>
                      </div>
                      <div class="col-md-6">
                        <small class="text-muted">Pickup</small>
                        <h4 class="fs-6">{state.shipmentDetail.sender.address+","+state.shipmentDetail.sender.city+" "+state.shipmentDetail.sender.country}</h4>
                      </div>
                      <div class="col-md-6">
                        <small class="text-muted">Delivery</small>
                        <h4 class="fs-6">{state.shipmentDetail.receiver.address+","+state.shipmentDetail.receiver.city+" "+state.shipmentDetail.receiver.country}</h4>
                      </div>
                      <div class="col-md-6">
                        <small class="text-muted">Sender</small>
                        <h4 class="fs-5"><a href="">{state.shipmentDetail.sender.name}</a></h4>
                      </div>
                      <div class="col-md-6">
                        <small class="text-muted">Reciver</small>
                        <h4 class="fs-5"><a href="">{state.shipmentDetail.receiver.name}</a></h4>
                      </div>
                      <div class="col-md-6">
                        <small class="text-muted">Type</small>
                        <h4 class="fs-5">{state.shipmentDetail.type}</h4>
                      </div>
                      <div class="col-md-6">
                        <small class="text-muted">Item(s)</small>
                        <h4 class="fs-5">{state.parcel_count}</h4>
                      </div>
                    </div>
                  </section>
        
                  <hr class="m-4"/>
        
        
                  <section class="ps-4">
                    <h3 class="fs-5 pb-3">Order details</h3>
                    <div class="row">
                      <div class="col-md-6">
                        <small class="text-muted">Carrier</small>
                        <h4 class="fs-5"><a href=""><img src={"https://cdn.shiprath.in/images/"+state.shipmentDetail.carrier_image} alt="" class="img-fluid" title="Fedex Inc."/></a></h4>
                        
                      </div>
                      <div class="col-md-6">
                        <small class="text-muted">Order on</small>
                        <h4 class="fs-5"><Moment format="MMM DD YYYY">
                        {new Date(state.shipmentDetail.created*1000)}
            </Moment> 
                        <small class="text-muted">:<Moment format="hh:mm">
                        {new Date(state.shipmentDetail.created*1000)}
            </Moment></small></h4>
                      </div>
                      <div class="col-md-6">
                        <small class="text-muted">Total Price #</small>
                        <h4 class="fs-5">₹ {state.shipmentDetail.total_amount}</h4>
                      </div>
                      <div class="col-md-6">
                        <small class="text-muted">Total Weight</small>
                        <h4 class="fs-5">{state.shipmentDetail.total_weight } KG</h4>
                      </div>
                    </div>
                  </section>
        
                  <hr class="m-4"/>
        
                  <section class="ps-4">
                    <h3 class="fs-5 pb-3">Service Inforamtion </h3>
                    <div class="row">
                      <div class="col-md-6">
                        <small class="text-muted">Service Name</small>
                        <h4 class="fs-6">{state.shipmentDetail.service_provider}</h4>
                      </div>
                
                    </div>
                  </section>

                  <hr class="m-4"/>
                  

                  <section class="ps-4">
                    <h3 class="fs-5 pb-3">Shipping Items</h3>
                    <div class="row">
                      {state.shipmentDetail.items.map((item)=>
                      <>
                      <div class="col-md-12">
                        <h4 class="fs-6">{item.item_description}</h4>
                        <small class="text-muted">{state.shipmentDetail.total_weight} KG - {state.shipmentDetail.height}x{state.shipmentDetail.width}x{state.shipmentDetail.length} m</small>
                      </div>
                      
                      </>
)}
                    </div>
                  </section>
                  
                  
            </section>

            
  
          </div>
        </div>
      </section>
      
      </div>             */}
      {/* <?php
            include('header.php');
        ?>

        <?php
            include('left_nav.php');
        ?> */}
       <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
    <section class="home-section">
          <Sub_header/>
    
            
             
            
<section>
            <div class="row my-3">
                <div class="col-12 col-md">
                    <div class="d-flex">
                        <div class="back_btn_wrap">
                            <a href="" class="back_btn">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="corner-up-left" class="lucide lucide-corner-up-left"><polyline points="9 14 4 9 9 4"></polyline><path d="M20 20v-7a4 4 0 0 0-4-4H4"></path></svg>
                            </a>
                        </div>
                        <div class="">
                            <h2><small>Shipment #</small>{state.shipmentDetail.shipment_id}</h2>
                        </div>
                       
               
                    </div>
                    
                </div>
                {/* <div class="col-12 col-md-2 text-end">
                    xxx
                </div> */}
                <div class="col-12 col-md-2 text-end shipm_tablew">
                    {/* <span class="status pickedup"> <i class="fa fa-check" aria-hidden="true"></i> Pickedup</span> */}
                    {state.shipmentDetail.status==0?  <span class="status pickup_awit"> <i class="fa fa-clock-o" aria-hidden="true"></i> Pending</span>:state.shipmentDetail.status==10?  <span class="status pickup_awit"> <i class="fa fa-clock-o" aria-hidden="true"></i> RTO</span>:state.shipmentDetail.status==16?  <span class="status pickup_awit"> <i class="fa fa-clock-o" aria-hidden="true"></i> RTO Delivered</span>:state.shipmentDetail.status==15?  <span class="status pickup_awit"> <i class="fa fa-clock-o" aria-hidden="true"></i>Booked</span>:state.shipmentDetail.status==1?    <span class="status pickup_awit"> <i class="fa fa-clock-o" aria-hidden="true"></i> Pickup Awaited</span>:state.shipmentDetail.status==2?  <span class="status pickedup"> <i class="fa fa-check" aria-hidden="true"></i> Picked Up</span>:state.shipmentDetail.status==3?  <span class="status in_transit"> <i class="fa fa-truck" aria-hidden="true"></i> In Transit</span>:state.shipmentDetail.status==4?   <span class="status out_f_deli"> <i class="fa fa-plane" aria-hidden="true"></i> Out for Delivery</span>:state.shipmentDetail.status==5?  <span class="status delivered"> <i class="fa fa-check-square-o" aria-hidden="true"></i> Delivered</span>:state.shipmentDetail.status==17?  <span class="status delivered"> <i class="fa fa-check-square-o" aria-hidden="true"></i> Pickup Failed</span>:state.shipmentDetail.status==6?    <span class="status cancelled"> <i class="fa fa-ban" aria-hidden="true"></i> Cancelled</span>:state.shipmentDetail.status==7?    <span class="status failed"> <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Failed</span>:state.shipmentDetail.status==8?  <span class="status pickup_awit"> <i class="fa fa-clock-o" aria-hidden="true"></i> NDR</span>:state.shipmentDetail.status==9?<>   <span class="status pickup_sechdls"> <i class="fa fa-calendar-o" aria-hidden="true"></i> Pickup Scheduled</span><br/><br/>
                            {state.shipmentDetail.pickup_date!=undefined && state.shipmentDetail.pickup_date!=""?  <small>on {state.shipmentDetail.pickup_date!=undefined?state.shipmentDetail.pickup_date:""} at {state.shipmentDetail.pickup_time!=undefined?state.shipmentDetail.pickup_time:""}</small>:""} </>:  <span class="status pickup_awit"> <i class="fa fa-clock-o" aria-hidden="true"></i> Not Available</span>}
                </div>
            </div>
            {failedstate.failed!=""?   
            <div class="alert alert-danger text-center" role="alert">
              Shipment Failed Due to "{failedstate.failed}"
              </div>:""}


    <div class="">
        <div class="row mt-3 order_box_cont">
            <div class="col-12 col-md-8 ">
                

               

                <div class="card border-0 mb-3">
                    <div class="card-body">
                        <div class="d-flex ">
                            <div class="border-end pe-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="truck" class="lucide lucide-truck"><path d="M5 18H3c-.6 0-1-.4-1-1V7c0-.6.4-1 1-1h10c.6 0 1 .4 1 1v11"></path><path d="M14 9h4l4 4v4c0 .6-.4 1-1 1h-2"></path><circle cx="7" cy="18" r="2"></circle><path d="M15 18H9"></path><circle cx="17" cy="18" r="2"></circle></svg>
                            </div>
                            <div class="ps-3 ">
                                <h6>Shipping Details</h6>
                            </div>
                        </div>
                        <hr/>
                        <div class="row">
                            <div class="col-12 col-md-4 mb-2">
                                <img src="./assets/img/carriers_logo/fedex.svg" alt="" class="img-fluid" title="Fedex Inc."/>
                            </div>
                            <div class="col-12 col-md-4 mb-2">
                                <small class="text-muted">Order No.</small>
                                <p><strong><a href="">{state.shipmentDetail.order_number}</a></strong></p>
                            </div>
                            <div class="col-12 col-md-4 mb-2">
                                <small class="text-muted">Order on</small>
                                <p><a href=""><Moment format="MMM DD YYYY hh:mm">
                        {new Date(state.shipmentDetail.created*1000)}
            </Moment> </a></p>
                            </div>
                            <div class="col-12 col-md-4 mb-2">
                                <small class="text-muted">Shipping Courier</small>
                                <p>{state.shipmentDetail.service_provider}</p>
                            </div>
                            <div class="col-12 col-md-4 mb-2">
                                <small class="text-muted">Shipping Courier AWB No.</small>
                                <p><a href="">{state.shipmentDetail.carrier_tracking_number?state.shipmentDetail.carrier_tracking_number:""}</a></p>
                            </div>
                            <div class="col-12 col-md-4 mb-2">
                                <small class="text-muted">Shipping Courier Service</small>
                                <p>{state.shipmentDetail.product_type_name?state.shipmentDetail.product_type_name:""}</p>
                            </div>
                            <div class="col-12 col-md-4 mb-2">
                                <small class="text-muted">Order Type</small>
                                <p>{state.shipmentDetail.mode}</p>
                            </div>
                            <div class="col-12 col-md-4 mb-2">
                                <small class="text-muted">Shipment Type</small>
                                <p>Forward</p>
                            </div>
                            <div class="col-12 col-md-4 mb-2">
                                <small class="text-muted">Courier Rule Applied</small>
                                <p>N/A</p>
                            </div>
                            <div class="col-12 col-md-4 mb-2">
                                <small class="text-muted">Zone Name</small>
                                <p>{state.shipmentDetail.zone_name?state.shipmentDetail.zone_name:""}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card border-0  mb-3">
                    <div class="card-body">
                    <div class="d-flex ">
                        <div class="border-end pe-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="package-open" class="lucide lucide-package-open"><path d="M20.91 8.84 8.56 2.23a1.93 1.93 0 0 0-1.81 0L3.1 4.13a2.12 2.12 0 0 0-.05 3.69l12.22 6.93a2 2 0 0 0 1.94 0L21 12.51a2.12 2.12 0 0 0-.09-3.67Z"></path><path d="m3.09 8.84 12.35-6.61a1.93 1.93 0 0 1 1.81 0l3.65 1.9a2.12 2.12 0 0 1 .1 3.69L8.73 14.75a2 2 0 0 1-1.94 0L3 12.51a2.12 2.12 0 0 1 .09-3.67Z"></path><line x1="12" x2="12" y1="22" y2="13"></line><path d="M20 13.5v3.37a2.06 2.06 0 0 1-1.11 1.83l-6 3.08a1.93 1.93 0 0 1-1.78 0l-6-3.08A2.06 2.06 0 0 1 4 16.87V13.5"></path></svg>
                        </div>
                        <div class="ps-3 ">
                            <h6>Package Details</h6>
                        </div>
                    </div>
                    <hr/>
                    <div class="row">
                        <div class="col-12 col-md-4 mb-2">
                            <small class="text-muted">Applicable Weight (in Kg)</small>
                            <p>{state.shipmentDetail.total_weight}KG</p>
                        </div>
                        <div class="col-12 col-md-4 mb-2">
                            <small class="text-muted">Dead Weight (in Kg)</small>
                            <p>{state.shipmentDetail.dead_weight}KG</p>
                        </div>
                        <div class="col-12 col-md-4 mb-2">
                            <small class="text-muted">Volumetric Weight (in kg)</small>
                            <p>{state.shipmentDetail.volumetric_weight}KG</p>
                        </div>
                        <div class="col-12 col-md-4 mb-2">
                            <small class="text-muted">Dimensions (in cm)</small>
                            <p>{state.shipmentDetail.height} x {state.shipmentDetail.width} x {state.shipmentDetail.length}</p>
                        </div>
                        <div class="col-12 col-md-4 mb-2">
                            <small class="text-muted">Number of Boxes</small>
                            <p>1</p>
                        </div>
                        <div class="col-12 col-md-4 mb-2">
                            <small class="text-muted">Shipment value</small>
                            <p>₹ 2000 <span class="badge rounded-pill bg-warning">{state.shipmentDetail.payment_mode}</span></p>
                        </div>
                    </div>
                    </div>
                </div>


                <div class="card border-0 mb-3">
                    <div class="card-body">
                        <div class="d-flex ">
                            <div class="border-end pe-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="package" class="lucide lucide-package"><path d="m7.5 4.27 9 5.15"></path><path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z"></path><path d="m3.3 7 8.7 5 8.7-5"></path><path d="M12 22V12"></path></svg>
                            </div>
                            <div class="ps-3 ">
                                <h6>Shipment Details</h6>
                            </div>
                        </div>
                        <hr/>
                        <div class="row">
                          
                            <div class="col-12 col-md">
                                <h6>Ship From:</h6>
                                    <small class="text-muted">Sender Name:</small>
                                    <p>{state.shipmentDetail.sender.name}</p>
                                    <small class="text-muted">Sender Address:</small>
                                    <p>{state.shipmentDetail.sender.address}, {state.shipmentDetail.sender.city}, {state.shipmentDetail.sender.state} {state.shipmentDetail.sender.pincode}</p>
                                    <small class="text-muted">Sender Email:</small>
                                    <p>{state.shipmentDetail.sender.email}</p>
                                    <small class="text-muted">Sender Mobile:</small>
                                    <p>{state.shipmentDetail.sender.mobile}</p>
                            </div>
                            <div class="col-12 col-md">
                                <h6>Ship To:</h6>
                                <small class="text-muted">Receiver Name:</small>
                                    <p>{state.shipmentDetail.receiver.name}</p>
                                    <small class="text-muted">Receiver Address:</small>
                                    <p>{state.shipmentDetail.receiver.address}, {state.shipmentDetail.receiver.city}, {state.shipmentDetail.receiver.state} {state.shipmentDetail.receiver.pincode}</p>
                                    <small class="text-muted">Receiver Email:</small>
                                    <p>{state.shipmentDetail.receiver.email}</p>
                                    <small class="text-muted">Receiver Mobile:</small>
                                    <p>{state.shipmentDetail.receiver.mobile}</p>
                            </div>
                        </div>
                    </div>
                </div>

                {state.shipmentDetail.status!=7?
                <div class="card border-0  mb-3">
                    <div class="card-body">
                    <div class="d-flex ">
                        <div class="border-end pe-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="dollar-sign" class="lucide lucide-dollar-sign"><line x1="12" x2="12" y1="2" y2="22"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                        </div>
                        <div class="ps-3 ">
                            <h6>Payment Details</h6>
                        </div>
                    </div>
                    <hr/>
                   
                    <div class="row">
                        <div class="col-12 col-md-4 mb-2">
                            <small class="text-muted">Transaction ID</small>
                            <p><a href="">{state.shipmentDetail.payment_transaction_id}</a></p>
                        </div>
                        <div class="col-12 col-md-4 mb-2">
                            <small class="text-muted">Payment Mode</small>
                            <p>Wallet</p>
                        </div>
                        <div class="col-12 col-md-4 mb-2">
                            <small class="text-muted">Total Paid Amount</small>
                            <p>₹ {state.shipmentDetail.total_amount}</p>
                        </div>
                        <div class="col-12 col-md-4 mb-2">
                            <small class="text-muted">Bill To (Customer)</small>
                            <p><a href="">{state.shipmentDetail.sender.name}</a></p>
                        </div>
                        <div class="col-12 col-md-4 mb-2">
                            <small class="text-muted">Customer Account No.</small>
                            <p>-----</p>
                        </div>
                    </div>
                    
                    </div>
                </div>
                :""}
                <div class="card border-0 mb-3">
                    <div class="card-body">
                    <div class="d-flex ">
                        <div class="border-end pe-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="shopping-basket" class="lucide lucide-shopping-basket"><path d="m5 11 4-7"></path><path d="m19 11-4-7"></path><path d="M2 11h20"></path><path d="m3.5 11 1.6 7.4a2 2 0 0 0 2 1.6h9.8c.9 0 1.8-.7 2-1.6l1.7-7.4"></path><path d="m9 11 1 9"></path><path d="M4.5 15.5h15"></path><path d="m15 11-1 9"></path></svg>
                        </div>
                        <div class="ps-3 ">
                            <h6>Product Details</h6>
                        </div>
                    </div>
                    <hr/>
                    <div class="table-responsive ">
                        <table class="table">
                            <thead>
                            <tr>
                                <th style={{width: "20px"}}>Name</th>
                                <th>Category</th>
                                <th>HSN</th>
                                <th>SKU</th>
                                <th>Qty</th>
                                <th>Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                            {state.shipmentDetail.items.map((item)=>
                      <>
                    
                                <td style={{width: "200px"}}>{item.item_description}</td>
                                <td>----</td>
                                <td>----</td>
                                <td>----</td>
                                <td>{item.item_quantity}</td>
                                <td>{item.item_value}</td>
                          
               </>)}
</tr>
                            </tbody>
                        </table>
                    </div>
                    </div>
                </div>

                
                
                
            </div>
            <div class="col-12 col-md-4">
            <section class="bg-white p-3 mb-3">
                <p>
                    {state.shipmentDetail.sender.city} <i class="fa fa-long-arrow-right" aria-hidden="true"></i> {state.shipmentDetail.receiver.city}
                    {/* <span class="text-end"><small class="text-muted">Location last updated on 3 May 2022 09:38</small></span> */}
                </p>

                
                {/* <p>Delivery ETA - <span class="text-muted">03-02-2022 @ 23:29</span></p> */}
            </section>
            <div class="card border-0 shadow-sm tracking_xb">
                <div class="card-body">
                    <div class="d-flex ">
                        <div class="border-end pe-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="package-search" class="lucide lucide-package-search"><path d="M21 10V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l2-1.14"></path><path d="m7.5 4.27 9 5.15"></path><polyline points="3.29 7 12 12 20.71 7"></polyline><line x1="12" x2="12" y1="22" y2="12"></line><circle cx="18.5" cy="15.5" r="2.5"></circle><path d="M20.27 17.27 22 19"></path></svg>
                        </div>
                        <div class="ps-3 ">
                            <h6>Tracking Info</h6>
                        </div>
                    </div>
                    <hr/>
                    {trackingstate.track!='' && stateship.shipmentDetail!='' ?
                                            <div class="progress-container  position-relative">
                                              {trackingstate.isLoading==false?
                                               trackingstate.track.history.length>0?
                                               trackingstate.track.history.map((sub)=>(
                                                <div class="step-container">
                                                <div class="step active">
                                                    <i class="material-icons-outlined">radio_button_checked</i>
                                                </div>
                                                <div class="progress"></div>
                                                <div class="step-info">
                <h4>{sub.message} </h4>
                <p>{sub.location}</p>
                <p><Moment format="MMM DD YYYY hh:mm:ss a">
                 {(sub.time)}
     </Moment></p>
            </div>
                                                </div>
                                             )) :"No Data Available"  :<ReactLoading type="bubbles" color="#0d6efd" style={{ position: 'absolute', right: '45%', height: '20px', width: '10%' }} />}
                                              
                                            </div>:"No Data Available"}
                      {/* :<ReactLoading type="bubbles" color="#0d6efd" style={{ position: 'absolute', right: '45%', height: '20px', width: '10%' }} />} 
                    */}
                </div>
                </div>
            </div>
        </div>
    </div>
</section>
    
   

        

    </section>


{/* <?php
include('footer.php');
?>   */}
             
    </div>
    
  )
}

export default Shipment_detail